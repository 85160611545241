var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "section-content" },
      [
        _c(
          "div",
          { staticClass: "list-container" },
          [
            _c(
              "div",
              { staticClass: "vld-parent" },
              [
                _c("loading", {
                  attrs: {
                    active: _vm.isLoading,
                    "can-cancel": false,
                    "is-full-page": _vm.fullPage,
                  },
                  on: {
                    "update:active": function ($event) {
                      _vm.isLoading = $event
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-container" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "new-group-button",
                    on: {
                      click: function ($event) {
                        return _vm.newGroup()
                      },
                    },
                  },
                  [_vm._v(" Cadastrar grupo ")]
                ),
                _c(
                  "div",
                  { staticClass: "search-container" },
                  [
                    _c(
                      "b-input-group",
                      { staticClass: "mb-0", attrs: { size: "sm" } },
                      [
                        _c(
                          "b-input-group-prepend",
                          { attrs: { "is-text": "" } },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                        _c("b-form-input", {
                          attrs: { type: "search", placeholder: "Buscar..." },
                          model: {
                            value: _vm.filterText,
                            callback: function ($$v) {
                              _vm.filterText = $$v
                            },
                            expression: "filterText",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("b-table", {
              attrs: {
                fields: _vm.fields,
                items: _vm.filteredGroups,
                "per-page": _vm.perPage,
                "current-page": _vm.currentPage,
              },
              on: { filtered: _vm.onFiltered },
              scopedSlots: _vm._u([
                {
                  key: "cell(edit)",
                  fn: function (row) {
                    return [
                      _c("img", {
                        staticClass: "item-table",
                        attrs: { src: _vm.editIcon },
                        on: {
                          click: function ($event) {
                            return _vm.editGroup(
                              row.item,
                              row.index,
                              $event.target
                            )
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(active)",
                  fn: function (row) {
                    return [
                      _c("b-form-checkbox", {
                        staticStyle: { "padding-top": "13px" },
                        attrs: { switch: "", size: "lg", name: "check-button" },
                        on: {
                          change: function ($event) {
                            return _vm.enableGroup(row.item, row.index, $event)
                          },
                        },
                        model: {
                          value: row.item.active,
                          callback: function ($$v) {
                            _vm.$set(row.item, "active", $$v)
                          },
                          expression: "row.item.active",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "cell(remove)",
                  fn: function (row) {
                    return [
                      _c("img", {
                        staticClass: "item-table",
                        attrs: { src: _vm.deleteIcon },
                        on: {
                          click: function ($event) {
                            return _vm.deleteGroup(
                              row.item,
                              row.index,
                              $event.target
                            )
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c(
              "div",
              { staticClass: "pagination-container" },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "my-3" },
                      [
                        _c("b-pagination", {
                          attrs: {
                            "total-rows": _vm.totalRows,
                            "per-page": _vm.perPage,
                          },
                          model: {
                            value: _vm.currentPage,
                            callback: function ($$v) {
                              _vm.currentPage = $$v
                            },
                            expression: "currentPage",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      { staticClass: "my-3" },
                      [
                        _c("b-form-select", {
                          attrs: {
                            id: "per-page-select",
                            options: _vm.pageOptions,
                          },
                          model: {
                            value: _vm.perPage,
                            callback: function ($$v) {
                              _vm.perPage = $$v
                            },
                            expression: "perPage",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "b-modal",
          {
            ref: "modal",
            attrs: {
              id: "modal-prevent-closing",
              "ok-title": _vm.editing ? "Editar" : "Concluir",
              "cancel-title": "Cancelar",
              "cancel-variant": "transparent",
              title: _vm.modalTitle,
              "modal-class": "edit-title",
              size: "lg",
            },
            on: { hidden: _vm.resetModal, ok: _vm.handleModalOk },
          },
          [
            _c(
              "b-form",
              {
                on: {
                  submit: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.handleSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Nome do grupo",
                              "label-for": "name-input",
                              state: _vm.nameState,
                              "invalid-feedback": "Nome é necessário",
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "border border-dark",
                              attrs: {
                                state: _vm.nameState,
                                id: "name-input",
                                required: "",
                                placeholder: "Digite o nome...",
                              },
                              model: {
                                value: _vm.group.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.group, "name", $$v)
                                },
                                expression: "group.name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Descrição do grupo",
                              "label-for": "name-input",
                            },
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "border border-dark",
                              attrs: {
                                id: "group-description",
                                placeholder: "Digite a descrição...",
                              },
                              model: {
                                value: _vm.group.description,
                                callback: function ($$v) {
                                  _vm.$set(_vm.group, "description", $$v)
                                },
                                expression: "group.description",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          { attrs: { label: "Permissões" } },
                          [
                            _c("b-form-checkbox-group", {
                              attrs: {
                                id: "permissionGroup",
                                options: _vm.permissionsOptions,
                                stacked: "",
                              },
                              model: {
                                value: _vm.selectedPermissions,
                                callback: function ($$v) {
                                  _vm.selectedPermissions = $$v
                                },
                                expression: "selectedPermissions",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-col",
                      [
                        _c("b-table", {
                          staticClass: "user-table",
                          staticStyle: {
                            "{ max-height": "300px",
                            height: "300px",
                          },
                          attrs: {
                            fields: _vm.usersField,
                            items: _vm.filteredUsersGroups,
                            "sticky-header": "",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(action)",
                              fn: function (row) {
                                return [
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      size: "md",
                                      name: "check-button",
                                      state: _vm.usersState,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.associateUser(
                                          row.item,
                                          row.index,
                                          $event
                                        )
                                      },
                                    },
                                    model: {
                                      value: row.item.action,
                                      callback: function ($$v) {
                                        _vm.$set(row.item, "action", $$v)
                                      },
                                      expression: "row.item.action",
                                    },
                                  }),
                                ]
                              },
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "background-section-title" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v("Grupos")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }